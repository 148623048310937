import React from "react";
import s from "./TermsOfService.module.css"

interface Props {
}

const TermsOfService: React.FC<Props> = (props: Props) => {
  return (
        <div className={s.TermsOfService}>
          <div className={s.TermsOfService_ContentContainer}>
            <h1>Terms of Service</h1>
          <div className={s.section}>
    <h2 className={s.heading}>1. Acceptance of Terms</h2>
    <p>By accessing and using the esscents.ai website ("Site"), you accept and agree to be bound by the terms and provisions of this agreement. If you do not agree to abide by the above, please do not use this Site.</p>
  </div>

  <div className={s.section}>
    <h2 className={s.heading}>2. Description of Service</h2>
    <p>esscents.ai is currently operating as a landing page that allows users to join a waitlist for future services. The primary function of the Site at this time is to collect email addresses from interested parties for the purpose of future communications regarding the launch and availability of esscents.ai services.</p>
  </div>

  <div className={s.section}>
    <h2 className={s.heading}>3. Use of the Service</h2>
    <h3>3.1. Eligibility</h3>
    <p>You must be at least 13 years old to use this Site. By using this Site and agreeing to these terms, you warrant and represent that you are at least 13 years of age.</p>
    <h3>3.2. Legal Compliance</h3>
    <p>You agree to use the Site only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the Site. Prohibited behavior includes harassing or causing distress or inconvenience to any other user, transmitting obscene or offensive content, or disrupting the normal flow of dialogue within the Site.</p>
    <h3>3.3. Accurate Information</h3>
    <p>You agree to provide accurate and complete information when joining the waitlist. You are solely responsible for the accuracy of the email address you provide.</p>
  </div>

  <div className={s.section}>
    <h2 className={s.heading}>4. Intellectual Property Rights</h2>
    <h3>4.1. Site Content</h3>
    <p>The content on this Site, including without limitation, the text, software, scripts, graphics, photos, sounds, music, videos, interactive features and the like ("Content") and the trademarks, service marks and logos contained therein ("Marks"), are owned by or licensed to esscents.ai, subject to copyright and other intellectual property rights under the law. Content on the Site is provided to you AS IS for your information and personal use only and may not be used, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other purposes whatsoever without the prior written consent of the respective owners.</p>
    <h3>4.2. Feedback</h3>
    <p>If you provide esscents.ai with any feedback or suggestions regarding the Site ("Feedback"), you hereby assign to esscents.ai all rights in such Feedback and agree that esscents.ai shall have the right to use and fully exploit such Feedback and related information in any manner it deems appropriate.</p>
  </div>

  <div className={s.section}>
    <h2 className={s.heading}>5. User Data and Privacy</h2>
    <h3>5.1. Data Collection</h3>
    <p>By entering your email address to join the waitlist, you consent to esscents.ai collecting and storing this information for the purpose of sending you updates about the launch of our services and other related communications.</p>
    <h3>5.2. Privacy Policy</h3>
    <p>Your use of the Site is also governed by our Privacy Policy, which can be found at [link to privacy policy]. Please review this document for more information on how we collect, use, and protect your data.</p>
    <h3>5.3. Marketing Communications</h3>
    <p>By joining our waitlist, you agree to receive marketing communications from us. You can opt out of these communications at any time by using the unsubscribe link provided in each email or by contacting us directly.</p>
  </div>

  <div className={s.section}>
    <h2 className={s.heading}>6. Disclaimers and Limitation of Liability</h2>
    <h3>6.1. Service "As Is"</h3>
    <p>The Site is provided on an "as is" and "as available" basis. esscents.ai makes no representations or warranties of any kind, express or implied, as to the operation of the Site or the information, content, materials, or products included on the Site.</p>
    <h3>6.2. No Guarantees</h3>
    <p>Joining the waitlist does not guarantee access to future services or products. esscents.ai reserves the right to determine eligibility for future services at its sole discretion.</p>
    <h3>6.3. Limitation of Liability</h3>
    <p>To the full extent permissible by applicable law, esscents.ai disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose. esscents.ai will not be liable for any damages of any kind arising from the use of this Site, including, but not limited to direct, indirect, incidental, punitive, and consequential damages.</p>
  </div>

  <div className={s.section}>
    <h2 className={s.heading}>7. Indemnification</h2>
    <p>You agree to indemnify, defend and hold harmless esscents.ai, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations.</p>
  </div>

  <div className={s.section}>
    <h2 className={s.heading}>8. Governing Law and Jurisdiction</h2>
    <p>These Terms shall be governed and construed in accordance with the laws of [Your State/Country], without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.</p>
  </div>

  <div className={s.section}>
    <h2 className={s.heading}>9. Changes to Terms</h2>
    <p>esscents.ai reserves the right to modify these terms of service at any time. We will always post the most current version on our Site. By continuing to use the Site after changes become effective, you agree to be bound by the revised terms.</p>
  </div>

  <div className={s.section}>
    <h2 className={s.heading}>10. Severability</h2>
    <p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>
  </div>

  <div className={s.section}>
    <h2 className={s.heading}>11. Entire Agreement</h2>
    <p>These Terms, including any legal notices and disclaimers contained on this Site, constitute the entire agreement between esscents.ai and you in relation to your use of this Site, and supersede all prior agreements and understandings with respect to the same.</p>
  </div>
          </div>

</div>
  );
};

TermsOfService.defaultProps = {
}

export default TermsOfService