import React from "react";
import ScrollableFragranceCard from "../ScrollableFragranceCard";
import s from "./ScrollableFragranceCards.module.css";
import angelsshare from '../../assets/angelsshare.jpg';  // Importing the image
import b540 from '../../assets/b540.jpg';  // Importing the image
import babycat from '../../assets/babycat.jpg';  // Importing the image
import biancolatte from '../../assets/biancolatte.jpg';  // Importing the image
import burberryher from '../../assets/burberryher.jpg';  // Importing the image
import hacivat from '../../assets/hacivat.jpg';  // Importing the image
import lemaleelixir from '../../assets/lemaleelixir.jpg';  // Importing the image
import naxos from '../../assets/naxos.jpg';  // Importing the image
import pdmdelina from '../../assets/pdmdelina.jpg';  // Importing the image
import pradalhomme from '../../assets/pradalhomme.jpg';  // Importing the image
import yedp from '../../assets/yedp.jpg';  // Importing the image
import goodgirl from '../../assets/goodgirl.jpg';  // Importing the image
import tobaccohoney from '../../assets/tobaccohoney.jpg';  // Importing the image
import kayali from '../../assets/kayali.jpg';  // Importing the image
import adpblumediterraneo from '../../assets/adpblumediterraneo.jpg';  // Importing the image
import useScreenSizeDetector from "../../utils/useScreenSizeDetector";

interface Props {
}

const ScrollableFragranceCards: React.FC<Props> = (props: Props) => {
  const initialCards = Array.from({ length: 15 }, (_, i) => i); // 15 cards (3 columns x 5 rows)
  const [cards, setCards] = React.useState<number[]>(initialCards);
  const { isMobile, isTablet, isDesktop } = useScreenSizeDetector();

  const fragranceImages = [
    {
      imageSrc: goodgirl,
      prompt: "A night out fragrance for women"
    },
    {
      imageSrc: pradalhomme,
      prompt: "Something light, airy and linen-y"
    },
    {
      imageSrc: yedp,
      prompt: "Something aquatic and cool for the gym that's not too expensive"
    },
    {
      imageSrc: b540,
      prompt: "Something that smells like this but under $100"
    },
    {
      imageSrc: hacivat,
      prompt: "Something I can wear at the beach"
    },
    {
      imageSrc: biancolatte,
      prompt: "An ambery/vanilla fragrance I can wear on a date"
    },
    {
      imageSrc: pdmdelina,
      prompt: "Something like this but with more projection"
    },
    {
      imageSrc: lemaleelixir,
      prompt: "Something sweet with good projection that I can wear clubbing"
    },
    {
      imageSrc: tobaccohoney,
      prompt: "Something woody and tobacco-y but feminine"
    },
    {
      imageSrc: burberryher,
      prompt: "Something that reminds me of this but cheaper"
    },
    {
      imageSrc: adpblumediterraneo,
      prompt: "I like this scent profile but it's too feminine. Find me something more unisex."
    },
    {
      imageSrc: naxos,
      prompt: "Something I can wear on a summer night in Italy"
    },
    {
      imageSrc: babycat,
      prompt: "I like this scent but it's too expensive"
    },
    {
      imageSrc: angelsshare,
      prompt: "A sexy winter fragrance that's gourmand-y and sweet"
    },
    {
      imageSrc: kayali,
      prompt: "A cheap gourmand for women"
    },
  ]
  
  const appendMoreCards = () => {
    setCards((prevCards) => [
      ...prevCards,
      ...initialCards,
    ]);
  };

  React.useEffect(() => {
    const container = document.querySelector(`.${s.ScrollableFragranceCards}`);

    if (!isMobile) { 
      container!.scrollTop = 600; // Adjust this value based on how far down you want to start
    }

    const scrollInterval = setInterval(() => {
      if (!isMobile) { 
        if (container!.scrollTop + container!.clientHeight >= container!.scrollHeight - 10) {
          appendMoreCards(); // Append more cards when reaching the bottom
        }
        container!.scrollTop += 1; // Increase the scroll speed by scrolling 3px per interval
      } else {
        if (container!.scrollLeft + container!.clientWidth >= container!.scrollWidth - 10) {
            appendMoreCards(); // Append more cards when reaching the end
        }
        container!.scrollLeft += 4; // Scroll horizontally
      }
    }, 40); // Adjust the interval timing for smoother or faster scrolling

    return () => clearInterval(scrollInterval);
  }, [isMobile]);

  return (
    <div className={s.ScrollableFragranceCards}>
      {cards.map((_, index) => (
        <ScrollableFragranceCard key={index} imageSrc={fragranceImages[index % 15].imageSrc} promptText={fragranceImages[index % 15].prompt}/>
      ))}
    </div>
  );
};

ScrollableFragranceCards.defaultProps = {
}

export default ScrollableFragranceCards