import React from "react";
import { Typography, Image } from "antd";
import s from "./ScrollableFragranceCard.module.css"
import image1 from '../../assets/naxos.jpg';  // Importing the image

interface Props {
  imageSrc: string;
  promptText: string;
}

const ScrollableFragranceCard: React.FC<Props> = (props: Props) => {
  return (
    <div className={s.ScrollableFragranceCard}>
      <div className={s.ScrollableFragranceCard_ImageContainer}>
        <div 
          className={s.ScrollableFragranceCard_Image}
          style={{ "--img": `url(${props.imageSrc})` } as React.CSSProperties} // Ensure typing is correct
        />
        <div className={s.ScrollableFragranceCard_GradientOverlay}></div> {/* Add the gradient overlay */}
      </div>
      <div className={s.ScrollableFragranceCard_SearchTextContainer}>
        <Typography.Text className={s.ScrollableFragranceCard_SearchText}>
          {props.promptText}
        </Typography.Text>
      </div>
    </div>
  );
};

ScrollableFragranceCard.defaultProps = {
}

export default ScrollableFragranceCard