import React from "react";
import s from "./PrivacyPolicy.module.css"

interface Props {
}

const PrivacyPolicy: React.FC<Props> = (props: Props) => {
  return (
        <div className={s.PrivacyPolicy}>
          <div className={s.PrivacyPolicy_ContentContainer}>
          <h1>Privacy Policy</h1>    
          <section>
            <h2>1. Information We Collect</h2>
            <p>When you visit our website and sign up for our waitlist, we collect the following personal information:</p>
            <ul>
              <li>Email address</li>
              <li>Date and time of your visit</li>
            </ul>
          </section>
    
          <section>
            <h2>2. How We Use Your Information</h2>
            <p>We use the information we collect to:</p>
            <ul>
              <li>Provide, operate, and maintain our website</li>
              <li>Add you to our waitlist for future services</li>
              <li>Send you updates about our launch and services</li>
              <li>Improve and personalize our website</li>
              <li>Understand how you use our website</li>
              <li>Develop new products, services, and features</li>
            </ul>
          </section>
    
          <section>
            <h2>3. Email Communications</h2>
            <p>By joining our waitlist, you agree to receive email communications from us. You can opt out at any time using the unsubscribe link in each email or by contacting us directly.</p>
          </section>
    
          <section>
            <h2>4. Data Storage and Security</h2>
            <p>We use industry-standard security measures to protect your personal information. However, no method of transmission over the Internet is 100% secure.</p>
          </section>
    
          <section>
            <h2>5. Cookies</h2>
            <p>We use cookies to track activity on our website and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</p>
          </section>
    
          <section>
            <h2>6. Third-Party Services</h2>
            <p>We may employ third-party companies and individuals to facilitate our website or provide services on our behalf. These third parties have access to your personal information only to perform specific tasks on our behalf.</p>
          </section>
    
          <section>
            <h2>7. Children's Privacy</h2>
            <p>Our website is not intended for children under 13 years of age. We do not knowingly collect personal information from children under 13.</p>
          </section>
    
          <section>
            <h2>8. Changes to This Privacy Policy</h2>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
          </section>
    
          <section>
            <h2>9. Your Rights</h2>
            <p>Depending on your location, you may have certain rights regarding your personal information, such as the right to access, correct, or delete your data.</p>
          </section>
          </div>
        </div>
      );
};

PrivacyPolicy.defaultProps = {
}

export default PrivacyPolicy