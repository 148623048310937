import { Typography } from "antd";
import React from "react";
import ScrollableFragranceCards from "../../components/ScrollableFragranceCards";
import s from "./Landing.module.css"
import { Input, Space, Button, Form, Alert } from 'antd';
import Confetti from 'react-confetti'
import {
  ArrowRightOutlined
} from '@ant-design/icons';
import { Lead } from "../../apiClient/Leads";
import { useNavigate } from "react-router-dom";
import useScreenSizeDetector from "../../utils/useScreenSizeDetector"
import classNames from "classnames";

interface Props {
}


const Landing: React.FC<Props> = (props: Props) => {
  const [showFormSubmissionError, setShowFormSubmissionError] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [leadEmail, setLeadEmail] = React.useState<string>()
  const [showConfetti, setShowConfetti] = React.useState<boolean>()
  const { isMobile, isTablet, isDesktop } = useScreenSizeDetector();

  const navigate = useNavigate();

  const onClickTOSLink = () => {
    navigate("/terms-of-service");
  };

  const onClickPrivacyPolicyLink = () => {
    navigate("/privacy-policy");
  };

  React.useEffect(() => {
    const existingLeadEmail = localStorage.getItem('leadEmail');
    if (existingLeadEmail) { 
      setLeadEmail(existingLeadEmail)
    }
  }, [setLeadEmail])

  const onCreateLeadSuccess = React.useCallback((leadEmail: string) => {
    setShowFormSubmissionError(false)
    localStorage.setItem('leadEmail', leadEmail);
    setShowConfetti(true)
    setLeadEmail(leadEmail)
  }, [setShowFormSubmissionError, setLeadEmail])

  const createLead = React.useCallback(async(leadEmail: string) => {
    try {
        if (!leadEmail) { 
          return
        }

        await new Lead().createLead({
          email: leadEmail
        })

        onCreateLeadSuccess(leadEmail)
    } catch (e) { 
      setShowFormSubmissionError(true)
    }
  }, [setShowFormSubmissionError, onCreateLeadSuccess])

  const onJoinWaitlistFormSubmit = React.useCallback((values: {email: string}) => {
    setIsSubmitting(true)
    createLead(values.email)
    setIsSubmitting(false)
  }, [createLead, setIsSubmitting])

  const validateMessages = {
    required: 'Please enter a valid email',
    types: {
      email: 'Please enter a valid email',
    },
  };

  const heading = (
    <Typography.Title className={
      classNames({
        [s.Landing_HeadingText]: true,
        [s.Landing_HeadingText_MobileNoMargin]: isMobile && leadEmail,
      })
    }>
      <b>Esscents</b><i>.ai</i>
    </Typography.Title>
  )

  const subHeading = (
    <Typography.Text className={s.Landing_SubheadingText}>
      {isMobile && "Your perfect scent, brought to you by AI"}
      {!isMobile && "A new era of fragrance discovery"}
    {/* Your perfect scent, brought to you by ai */}
  </Typography.Text>
  )

  const confetti = <Confetti
    width={window.innerWidth}
    height={window.innerHeight}
    recycle={false}
  />

  const thankYouContainer = (
    <div className={s.Landing_ThankYouContainer}>
      <Typography.Text className={s.Landing_ThankYouHeadlineText}>
        Thank you for joining our waitlist! 🎉
      </Typography.Text>
      <Typography.Text className={s.Landing_ThankYouBodyText}>
        We’re thrilled to have you on board! While we're putting the final touches on things, we'll make sure to keep you in the loop. Expect to hear from us at <strong>{leadEmail}</strong> as soon as we're ready!
      </Typography.Text>
    </div>
  )

  const desktopWaitlistForm = (
    <Form
      onFinish={onJoinWaitlistFormSubmit}
      validateMessages={validateMessages}
      className={s.Landing_JoinWaitlistForm}
    >
      <Form.Item name={['email']} rules={[{ type: 'email', required: true }]}>
        <Space.Compact>
        <Input placeholder="Email" autoFocus={true} className={s.Landing_EmailInput} defaultValue={""} type="email" id="email" name="email" autoComplete="email"/>
          <Button type="primary" className={s.Landing_SubmitButton} loading={isSubmitting}>Try it <ArrowRightOutlined /></Button>
        </Space.Compact>
      </Form.Item>
    </Form>
  )

  const desktopSrollableFragranceCards = (
    <div className={s.Landing_RightContainer}>
      <ScrollableFragranceCards />
    </div>
  )

  const footer = (
    <div className={s.Landing_FooterContainer}>
      <Typography.Link className={s.Landing_FooterLink} onClick={onClickTOSLink}>
        {"Terms of service"}
      </Typography.Link>
      |
      <Typography.Link className={s.Landing_FooterLink} onClick={onClickPrivacyPolicyLink}>
        {"Privacy policy"}
      </Typography.Link>
    </div>
  )

  const desktopView = (
    <div className={s.Landing}>
      <div className={s.Landing_LeftContainer}>
        <div className={s.Landing_CTAContainer}>
          {heading}
          {subHeading}
          {showConfetti && confetti}
          {leadEmail && thankYouContainer}
          {!leadEmail && desktopWaitlistForm}
          {showFormSubmissionError && <Alert message="Something went wrong. Please try again" type="error" showIcon className={s.Landing_FormSubmissionErrorAlert}/>}
          {footer}
        </div>
      </div>
      {desktopSrollableFragranceCards}
   </div>
  )

  const mobileWaitlistForm = (
    <Form
      onFinish={onJoinWaitlistFormSubmit}
      validateMessages={validateMessages}
      className={s.Landing_JoinWaitlistForm}
    >
      <Form.Item name={['email']} rules={[{ type: 'email', required: true }]} className={s.Landing_EmailInputFormItem}>
        <Input placeholder="Email" autoFocus={true} className={s.Landing_EmailInput} defaultValue={""} type="email" id="email" name="email" autoComplete="email"/>
      </Form.Item>
      <Form.Item>
        <Button type="primary" className={s.Landing_SubmitButton} htmlType="submit" loading={isSubmitting}>Try it <ArrowRightOutlined /></Button>
      </Form.Item>
    </Form>
  )

  const mobileView = (
    <div className={s.Landing_Mobile}>
      {showConfetti && confetti}
      <div className={classNames({
        [s.Landing_Mobile_HeaderContainer]: true,
        [s.Landing_Mobile_HeaderContainer_PaddingTop36]: leadEmail,
      })}>
        {heading}
        {subHeading}
      </div>
      <ScrollableFragranceCards />
      <div className={s.Landing_Mobile_BottomContainer}>
        {!leadEmail && mobileWaitlistForm}
        {leadEmail && thankYouContainer}
        {footer}
      </div>
   </div>
  )

  return (
    <>
      {!isMobile && desktopView}
      {isMobile && mobileView}
    </>
  );
};

Landing.defaultProps = {
}

export default Landing